<template>
    <div>
        <v-list 
            flat
            dense
            class="pb-0"
        >
            <v-list-item class="pb-2">
                <v-list-item-action>
                    <v-checkbox 
                        :input-value="allAreSelected"
                        @click="toggleSelected()"
                    />   
                </v-list-item-action>
                <v-list-item-action>
                    <v-checkbox 
                        v-model="enableGpio"
                        :label="$t(isMobileSize() ? 'Downlinks.enableGpio.mobile' : 'Downlinks.enableGpio.desktop')"
                    />   
                </v-list-item-action>  
                <v-spacer/>
                <v-list-item-action>
                    <v-checkbox 
                        v-model="enableOnboardSensors"
                        :label="$t(isMobileSize() ? 'Downlinks.enableOnboardSensors.mobile' : 'Downlinks.enableOnboardSensors.desktop')"
                    />   
                </v-list-item-action>  
                <v-spacer/>
                <v-list-item-action>
                    <v-checkbox 
                        v-model="enableGps"
                        :label="isMobileSize() ? $t('Downlinks.enableGps.mobile') : $t('Downlinks.enableGps.desktop')"
                    />   
                </v-list-item-action> 
                <v-spacer/>
                <!-- TODO: currently only admins can see the battery calculator -->
                <v-list-item-action v-if="isAdmin">
                    <v-btn
                        @click="showBatteryLifeCalculator = true"
                        :icon="isMobileSize()"
                        :small="isMobileSize()"
                        color="primary"
                    >
                        <span v-if="!isMobileSize()">{{ $t('Downlinks.calculateBatteryLife')}}</span>
                        <v-icon>mdi-battery-clock</v-icon>
                    </v-btn>
                </v-list-item-action> 
                <v-list-item-action>
                    <v-btn
                        @click="generateDownlinks()"
                        :disabled="selectedFeatureNames.length == 0"
                        :icon="isMobileSize()"
                        :small="isMobileSize()"
                        color="primary"
                    >
                        <span v-if="!isMobileSize()">{{ $t('Downlinks.generateDownlinks')}}</span>
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-list-item-action> 
            </v-list-item>
            <v-divider/>
            <v-divider/> 
            <v-list-item-group 
                v-model="selectedFeatureNames"
                multiple
            >
                <template v-for="feature, featureName in featuresInDesired">
                    <div 
                        v-if="featureVersionsRequirementsMet(featureName)"
                        :key="featureName" 
                    >
                         <v-list-item 
                            :value="featureName"
                            :disabled="!isValid(featureName)"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"/>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title :style="!isValid(featureName) ? 'text-decoration: line-through' : ''">
                                        {{ translate(feature.title) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon v-if="isDirty(featureName)">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                            </template>
                        </v-list-item>
                        <v-divider/>
                    </div>
                </template> 
            </v-list-item-group>
        </v-list>
        <Dialog 
            v-model="showDownlinks"
            :title="$t('Downlinks.downlinks')"
            width="40%"
            :hidePadding="true"
        >
            <Downlinks />
        </Dialog>
        <Dialog 
            v-model="showBatteryLifeCalculator"
            :title="$t('BatteryLifeCalculator.title')"
            width="40%"
            :info-text="$t('BatteryLifeCalculator.info')"
        >
            <BatteryLifeCalculator />
        </Dialog>
    </div>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog.vue'
import Downlinks from '@/components/Downlinks.vue'
import BatteryLifeCalculator from '@/components/BatteryLifeCalculator.vue'

import stMinVersionMet from '@/utils/stMinVersionMet.js';
import stMaxVersionMet from '@/utils/stMaxVersionMet.js';

export default {
    components: {
        Dialog,
        Downlinks,
        BatteryLifeCalculator
    },
    data: () => ({
        selectedFeatureNames: [],
        showDownlinks: false,
        showBatteryLifeCalculator: false,
        enableGpio: false,
        enableGps: false,
        enableOnboardSensors: true
    }),
    computed: {
        features() {
            return this.$store.getters.features
        },
        featuresInDesired() {
            
            return Object.keys(this.features).
                filter((featureName) => this.featureNamesInDesired.includes(featureName)).
                reduce((featuresInDesired, key) => { return Object.assign(featuresInDesired, { [key]: this.features[key] })}, {});
        },
        featureNamesInDesired() {
            return this.$store.getters.featureNamesInDesired
        },
        allFeatureNames() {
            return Object.keys(this.featuresInDesired)
        },
        allSelectableFeatureNames() {
            // TODO return filtered list of all features that have valid hardware
            let selectableFeatures = []
            this.allFeatureNames.forEach(featureName => {
                if(this.isValid(featureName) && this.featureVersionsRequirementsMet(featureName)) {
                    selectableFeatures.push(featureName)
                }
            })
            return selectableFeatures
        },
        allAreSelected() {            
            return this.allSelectableFeatureNames.every(featureName => {
                return this.selectedFeatureNames.includes(featureName)
            })
        },
        isShowingDownlinks() {
            return this.$store.getters.showDownlinks
        },
        isAdmin() {
            return this.$store.getters.isAdmin
        },
        versions() {
            return this.$store.getters.versions
        },
    },
    methods: {
        toggleSelected() {
            // if all selectable items are selected, deselect all 
            if(this.allAreSelected) {
                this.selectedFeatureNames = []
            }
            // else select all items
            else {
                this.selectedFeatureNames = this.allSelectableFeatureNames
            }
        },
        isDirty(featureName) {
            return this.$store.getters.dirty(featureName)
        },
        isValid(featureName) {
            return this.$store.getters.valid(featureName)
        },
        generateDownlinks() {
            this.$store.dispatch("generateDownlinks", {
                selectedFeatureNames: this.selectedFeatureNames, 
                enableGpio: this.enableGpio, 
                enableGps: this.enableGps, 
                enableOnboardSensors: this.enableOnboardSensors
            })
            this.showDownlinks = true
        },
        selectAllDirtyFeatures() {
            this.selectedFeatureNames = []
            this.allFeatureNames.forEach(featureName => {
                if(this.isDirty(featureName)) {
                    this.selectedFeatureNames.push(featureName)
                }
            })
        },
        featureVersionsRequirementsMet(featureName) {
            let minVersions = this.features[featureName].min_version
            let maxVersions = this.features[featureName].max_version
            

            return stMinVersionMet(this.versions.st, minVersions.st) && stMaxVersionMet(this.versions, maxVersions ? maxVersions.st : null)
        }
    },
    created() {
        this.selectAllDirtyFeatures();
    },
    watch: {
        isShowingDownlinks(show) {
            if(show) {
                this.selectAllDirtyFeatures();
            }
        }
    }
}
</script>