<template>
    <div>
        <div 
            v-if="downlinks == null" 
            class="text-center py-8"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <v-list 
            v-else 
            class="py-0"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>{{ $t('Downlinks.format.title') }}</v-list-item-subtitle>
                    <v-list-item-action>
                        <v-radio-group
                            v-model="format"
                            row  
                        >
                            <v-radio 
                                :label="$t('Downlinks.format.hex')"
                                value="hex"
                            />
                            <v-radio
                                :label="$t('Downlinks.format.base64')"
                                value="base64"
                            />
                        </v-radio-group>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item v-for="downlink, index in downlinks" :key="index" >
                <v-list-item-content>
                    <v-list-item-subtitle class="text-wrap" style="word-break: break-word">
                        <b>{{ featureTranslation(downlink.featureName) }}</b> 
                        <span v-if="downlink.propertyGroupName">{{ " | " + propertyGroupTranslation(downlink.featureName, downlink.propertyGroupName) }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-wrap" style="word-break: break-word">
                        {{ formatDownlink(downlink.downlink) }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn 
                        @click="copyToClipboard(downlink.downlink.toUpperCase())"
                        icon
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
            <v-divider/> 
        </v-list>
        <Snackbar v-model="showSnackbar">
            {{ $t('Downlinks.copied') }}
        </Snackbar>
    </div>
</template>

<script>
import copyToClipboard from '@/utils/copyToClipboard.js'
import Snackbar from '@/components/dialogs/Snackbar.vue'

export default {
    components: {
        Snackbar
    },
    data: () => ({
        format: 'hex',
        showSnackbar: false
    }),
    computed: {
        downlinks() {
            return this.$store.getters.downlinks
        },
        features() {
            return this.$store.getters.features
        }
    },
    methods: {
        copyToClipboard(downlink) {
            this.showSnackbar = true
            return copyToClipboard(this.formatDownlink(downlink))
        },
        featureTranslation(featureName) {
            return this.translate(this.features[featureName].title)
        },
        propertyGroupTranslation(featureName, propertyGroupName) {
            return this.translate(this.features[featureName].property_groups[propertyGroupName].title)
        },
        formatDownlink(downlink) {
            if(this.format == "base64") {
                return Buffer.from(downlink, 'hex').toString('base64')
            }
            return downlink.toUpperCase()
        }
    }
}
</script>