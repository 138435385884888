<template>
    <v-snackbar
        v-model="show"
        timeout="1500"
    >
        <slot></slot>
        <template v-slot:action="{ attrs }">
            <v-btn
                icon
                v-bind="attrs"
                @click="show = false"  
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        value: Boolean
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set() {
                this.$emit('input', false)
            }
        }
    }
}
</script>