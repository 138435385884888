<template>
    <div>
        <Dialog
            v-model="showTemplateSelector"
            :title="template ? $t('TemplateFeaturesEditor.change') : $t('TemplateFeaturesEditor.select')"
            width="70%"
        >
            <TemplateSelector />
        </Dialog>
        <v-card 
            :rounded="isMobileSize() ? '0' : null"
            :elevation="isMobileSize() ? 0 : 2"
            :class="isMobileSize() ? 'pa-0' : 'pt-0'"
        >
            <v-card-title class="header">
                <span>{{ template ? translate(template.title) : "Configuration template" }}</span>
                <v-spacer/>
                <v-btn 
                    color="primary"
                    @click="showTemplateSelector = true"
                >
                    <v-icon left>mdi-file-document-edit-outline</v-icon>
                    <span>{{ template ? $t('TemplateFeaturesEditor.change') : $t('TemplateFeaturesEditor.select') }}</span>
                </v-btn>
                <v-btn 
                    v-if="template"
                    @click="template = null"
                    class="ml-2"
                >
                    <v-icon left>mdi-file-document-remove-outline</v-icon>
                    <span>Deselect template</span>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-4">
                <TemplateInputs 
                    :template="template" 
                />
                <p v-if="!template">
                    <span >{{ $t('TemplateFeaturesEditor.description_part_1') }}</span>
                    <br><br>
                    <span >{{ $t('TemplateFeaturesEditor.description_part_2') }}</span>
                </p>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import TemplateInputs from '@/components/TemplateInputs'
import Dialog from '@/components/dialogs/Dialog'
import TemplateSelector from '@/components/TemplateSelector'

export default {
    components: {
        TemplateInputs,
        Dialog,
        TemplateSelector        
    },
    data: () => ({
        showTemplateSelector: false
    }),
    computed: {
        template: {
            get() {
                return this.$store.getters.template
            },
            set(template) {
                this.$store.dispatch("setTemplate", template)
            }
        },
    }
}

</script>