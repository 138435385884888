<template>
    <v-slide-y-reverse-transition v-if="!loading">
        <v-hover 
            v-slot="{ hover }"
            open-delay="100"
        >
            <v-footer 
                app 
                bottom 
                fixed 
                padless 
                :class="hover || isMobileSize() ? 'header' : null"
                elevation="24"
            >
                <Dialog 
                    v-model="showDownlinks"
                    :title="$t('Downlinks.selectFeatures')"
                    width="60%"
                    :hidePadding="true"
                    :infoText="$t('Downlinks.description')"
                    infoHref="https://docs.iotracker.eu/configuration/downlinks/#feature-control-b0"
                >
                    <DownlinkSelector />
                </Dialog>
                <Dialog 
                    v-model="showChanges"
                    :title="$t('Changes.title')"
                    width="90%"
                    :hidePadding="isMobileSize()"
                >
                    <Changes />
                </Dialog>
                <v-row>
                    <v-col v-if="!savingIsDisabled && !isMobileSize()" cols=3>
                        <v-text-field
                            :label="$t('Editor.name')"
                            v-model="name"
                            class="ml-2"
                        >
                            <template v-if="nameDirty" v-slot:append>
                                <v-slide-x-reverse-transition>
                                    <v-icon
                                        @click="restoreName"
                                    >
                                        mdi-arrow-u-left-top    
                                    </v-icon>
                                </v-slide-x-reverse-transition>
                            </template>
                        </v-text-field>
                        
                    </v-col>
                    <v-spacer/>
                    <v-col :align="isMobileSize() ? 'left' : 'right'" class="my-3">
                        <v-btn
                            @click="showDownlinks = true"
                            :disabled="!valid"
                            :class="hover || isMobileSize() ? 'elevation-3' : ''"
                            :small="isMobileSize()"
                            color="primary"
                        >
                            <div v-if="isMobileSize()">
                                <v-icon left>mdi-cog-outline</v-icon>
                                <span>{{ $t("Editor.generate_downlinks.mobile") }}</span>
                            </div>
                            <span v-else>{{ $t("Editor.generate_downlinks.desktop") }}</span>
                        </v-btn>
                    </v-col>
                    <v-spacer v-if="isMobileSize()"/>
                    <v-col align="right" class="my-3" md=auto>
                        <v-btn
                            @click="showChanges = true"
                            :disabled="!dirty"
                            :class="hover || isMobileSize() ? 'elevation-3' : ''"
                            :small="isMobileSize()"
                            color="primary"
                        >
                            <div v-if="isMobileSize()">
                                <v-icon left>mdi-file-find</v-icon>
                                <span>{{ $t("Editor.view_changes.mobile") }}</span>
                            </div>
                            <span v-else>{{ $t("Editor.view_changes.desktop") }}</span>
                        </v-btn>
                    </v-col>
                    <v-spacer v-if="isMobileSize()"/>
                    <v-col 
                        v-if="!savingIsDisabled"
                        align="right" class="my-3" md=auto 
                    >
                        <v-btn
                            @click="saveChanges"
                            :disabled="(!dirty && !nameDirty) || !valid"
                            :class="hover || isMobileSize() ? 'elevation-3' : ''"
                            :small="isMobileSize()"
                            color="primary"
                        >
                            <div v-if="isMobileSize()">
                                <v-icon left>mdi-content-save</v-icon>
                                <span>{{ $t("Editor.save.mobile") }}</span>
                            </div>
                            <span v-else>{{ $t("Editor.save.desktop") }}</span>
                        </v-btn>
                    </v-col>
                    <v-spacer v-if="isMobileSize()"/>
                    <v-col md=1 v-if="!isMobileSize()">
                        <v-text-field
                            :value="$t(`DifficultySelector.difficulty.${difficulty}`)" 
                            :label="$t('DifficultySelector.title')"      
                            readonly
                            hide-details
                            @click="showDifficulty=true"
                            class="mr-2"
                        >
                        </v-text-field>
                        <Dialog
                            width="20%"
                            :title="$t('DifficultySelector.title')"
                            v-model="showDifficulty"
                        >
                            <DifficultySelector/>
                        </Dialog>   
                    </v-col>
                </v-row>       
            </v-footer>
        </v-hover>
    </v-slide-y-reverse-transition>
</template>

<script>
// import Downlinks from '@/components/Downlinks.vue'
import DownlinkSelector from '@/components/DownlinkSelector.vue'
import Changes from '@/components/Changes.vue'
import Dialog from '@/components/dialogs/Dialog.vue'
import DifficultySelector from '@/components/DifficultySelector.vue'

export default {
    components: {
        DownlinkSelector,
        Changes,
        Dialog,
        DifficultySelector
    },
    data: () => ({
        showChanges: false,
        showDifficulty: false
    }),
    computed: {
        showDownlinks: {
            get() {
                return this.$store.getters.showDownlinks
            },
            set(show) {
                this.$store.dispatch('showDownlinks', show)
            }
        },
        loading() {
            return this.$store.getters.loadingEditor
        },
        dirty() {
            return this.$store.getters.dirty()
        },
        valid() {
            return this.$store.getters.valid()
        },
        name: {
            get() {
                return this.$store.getters.name.desired
            },
            set(desiredName) {
                this.$store.dispatch("setName", desiredName)
            }
        },
        nameDirty() {
            return this.$store.getters.name.desired != this.$store.getters.name.actual
        },
        difficulty: {
            get() {
                return this.$store.getters.difficulty
            },
            set(newValue) {
                this.$store.dispatch("setDifficulty", newValue)
            }
        },
        savingIsDisabled() {
            return this.$store.getters.savingIsDisabled
        }
    },
    methods: {    
        restoreName() {
            this.$store.dispatch('restoreName')
        },
        saveChanges() {
            this.$store.dispatch('saveChanges')
        }
    }
}
</script>