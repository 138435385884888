/**
 * Calculates the estimated battery life of the ioTracker 3 based on its software configuration, expected utilization and environment variables
 * @param {number} onMovementTimer In minutes
 * @param {number} backupTimer In minutes
 * @param {number} movement Ratio of how long the device is in movement per day
 * @param {number} gpsTimesEnabled Times GPS is enabled
 * @param {number} gpsMessages 2 per minute, normal settings 8 minimum, 30 max
 */
export default function calculateBatteryLife(
    onMovementTimer,
    backupTimer,
    movement,
    gpsTimesEnabled, 
    gpsMessages
) {
    // Algorith settings
    const UPLINK_COUNTS_PP = 150; 
    const WIFI_COUNTS = 137;
    const GPS_CONSUMPTION = 3;
    const RESUME_COUNTS = 6;

    const HOURS_PER_DAY = 24;
    const MINUTES_PER_HOUR = 60;

    const TIMER_STARTUP = 254;


    // Calculation 
    let loraPerDayNormal = HOURS_PER_DAY * (1 - movement) * (MINUTES_PER_HOUR / backupTimer);
    let loraPerDayMoved = HOURS_PER_DAY * movement * (MINUTES_PER_HOUR / onMovementTimer);
    
    let ratio = loraPerDayMoved / (loraPerDayNormal + loraPerDayMoved);
    let gps = (gpsTimesEnabled * GPS_CONSUMPTION) + ((gpsMessages / RESUME_COUNTS) * gpsTimesEnabled);
    let noGps = TIMER_STARTUP - gps;
    let wifi = WIFI_COUNTS * noGps;
    let lora = UPLINK_COUNTS_PP * noGps;
    let tot = 1 / ((1 / lora) + ((1 / wifi) * ratio));
    
    let ioTracker2BatteryLifeDays = tot / (loraPerDayNormal + loraPerDayMoved);
    let ioTracker3BatteryLifeDays = ioTracker2BatteryLifeDays * 1.5;
    
    return ioTracker3BatteryLifeDays;
}
