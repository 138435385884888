<template>                
    <v-data-table
        :headers="headers"
        :items="changes"
        class="elevation-3"
        group-by="origin"
        hide-default-footer
        disable-pagination
        disable-sort
        mobile-breakpoint="0"
    >
        <template v-slot:[`group.header`]="{ group, isOpen, toggle }">
            <th colspan="4">
                {{group}}
                <v-icon @click="toggle" >{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>  
            </th>
        </template>
        <template v-slot:expanded-item>
            
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.propertyTitle="{ value }">
            <div class="text-wrap" style="word-break: break-word">{{ value }}</div>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data:() => ({
        
    }),
    computed: {
        changes() {
            return this.$store.getters.changes
        },
        headers() {
            return [
                { text: this.$t('Changes.propertyTitle'), value: 'propertyTitle' },
                { text: this.$t('Changes.actual'), value: 'actual' },
                { text: this.$t('Changes.desired'), value: 'desired' }
            ]
        }
    }
}
</script>