<template>
    <v-container fluid >
        <v-row>
            <v-col 
                cols=12 md=3 lg=2 
                v-if="!isMobileSize() || selected == null"
                class="pa-0"
            >
                <FeatureList />
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.lgAndDown"/>
            <v-col 
                cols=12 md=9 lg=8 xl=5
                :class="isMobileSize() ? 'pa-0' : 'pb-16'"
            >
                <v-slide-x-transition mode="out-in">
                    <TemplateFeaturesEditor v-if="showTemplateFeaturesEditor"/>
                    <FeatureEditor v-else-if="selected"/>
                    <v-card 
                        v-else-if="!isMobileSize()"
                    >
                        <v-card-text>{{ $t('FeatureEditor.select') }}</v-card-text>
                    </v-card>
                </v-slide-x-transition>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-row>
            <EditorFooter @showChanges="showChanges = true" />
        </v-row>
    </v-container>
</template>

<script>
import FeatureList from '@/components/FeatureList'
import FeatureEditor from '@/components/FeatureEditor'
import EditorFooter from '@/components/EditorFooter.vue'
import TemplateFeaturesEditor from '@/components/TemplateFeaturesEditor'

export default {
    components: {
        FeatureList,
        FeatureEditor,
        EditorFooter,
        TemplateFeaturesEditor
    },
    computed: {
        loading() {
            return this.$store.getters.spec == null
        },
        selected() {
            return this.$store.getters.selected
        },
        templateId() {
            return this.$route.params.template_id
        },
        dirty() {
            return this.$store.getters.dirty()
        },
        showTemplateFeaturesEditor() {
            return this.$store.getters.showTemplateFeaturesEditor
        }
    },
    data: () => ({
        showRouteWarning: false
    }),
    beforeRouteLeave (to, from, next) {
        if(!this.dirty) {
            return next()   
        }
        const answer = window.confirm(this.$t('Editor.route_leave_warning'))
        if (!answer) {
            return false
        }
        next()
    }

}
</script>