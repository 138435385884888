<template>
    <div>
        <span class="pa-0">{{ $t('BatteryLifeCalculator.movement') }}</span>
        <v-input>
            <v-slider
                v-model="movement"
                :min="0"
                :max="1"
                step=0.05
                :hide-details="true"
                class="pr-1"
            >
                <template v-slot:prepend>
                    {{ Math.round(movement * 100) + "%" }}
                </template>
            </v-slider>
        </v-input>
        <v-input>
            <v-text-field
                v-model="gpsTimesEnabled"
                :label="$t('BatteryLifeCalculator.gpsTimesEnabled')"
                type="number"
                hide-details="auto"
                filled
                @keydown.enter.prevent
            >
            </v-text-field>
        </v-input>
        <v-input>
            <v-text-field
                v-model="gpsMessages"
                :label="$t('BatteryLifeCalculator.gpsMessages')"
                type="number"
                hide-details="auto"
                filled
                @keydown.enter.prevent
            >
            </v-text-field>
        </v-input>
        <h3>{{ $t('BatteryLifeCalculator.estimate.title') }}</h3>
        {{ $t('BatteryLifeCalculator.estimate.days', [batteryLifeDays]) }}<br>
        {{ $t('BatteryLifeCalculator.estimate.daysTo15Percent', [batteryLifeDaysTo15Percent]) }}<br>
        {{ $t('BatteryLifeCalculator.estimate.years', [batteryLifeYears]) }}<br>
    </div>
</template>

<script>
import calculateBatteryLife from '@/utils/calculateBatteryLife.js'

export default {
    data: () => ({
        movement: 0.1,
        gpsTimesEnabled: 50, 
        gpsMessages: 1
    }),
    computed: {
        batteryLife() {
            return calculateBatteryLife(
                this.onMovementTimer,
                this.backupTimer,
                this.movement,
                this.gpsTimesEnabled,
                this.gpsMessages
            )
        },
        batteryLifeDays() {
            return Math.round(this.batteryLife)
        },
        batteryLifeYears() {
            return Math.round((this.batteryLife / 365) * 100) / 100
        },
        batteryLifeDaysTo15Percent() {
            return Math.round(this.batteryLife * 0.85)
        },
        onMovementTimer() {
            let onMovementTimer = this.$store.getters.property('feature_control', null, 'default_timer')
            return onMovementTimer.includes('h') ? (parseInt(onMovementTimer) * 60) : parseInt(onMovementTimer)
        },
        backupTimer() {
            let backupTimer = this.$store.getters.property('feature_control', null, 'alive_timer')
            return backupTimer.includes('h') ? (parseInt(backupTimer) * 60) : parseInt(backupTimer)
        }
    }
}
</script>